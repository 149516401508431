<template>
  <div id="app">
    <Preloader />
    <transition name="show_modal" mode="out-in">
      <Modal />
    </transition>
    <div class="content__main">
      <runLine />
      <transition name="component-fade" mode="out-in" class="router__view">
        <router-view></router-view>
      </transition>
      <Footer />
    </div>
    <notifications group="foo" position="top right" :duration="8000" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Preloader from './components/Preloader.vue';
import Modal from './components/Modal_wrapper.vue';
import Footer from './components/Footer.vue';
import runLine from './components/run_line.vue';
export default {
  name: 'App',
  components: {
    Preloader,
    Modal,
    Footer,
    runLine,
  },
  data() {
    return {
      interval: null,
    };
  },
  methods: {
    func_get_notice() {
      if (this.USER_STATE) {
        let url = '/get_notice';
        window.sendRequestAuth(url, {}).then(result => {
          if (!result) return;
          this.$store.commit('CHANGE_NOTICE_LIST', result);
        });
      }
      this.interval = setTimeout(this.func_tick, 10000);
    },
    func_tick() {
      if (this.USER_STATE) {
        let url = '/get_notice';
        window.sendRequestAuth(url, {}).then(result => {
          if (!result) return;
          this.$store.commit('CHANGE_NOTICE_LIST', result);
          this.interval = setTimeout(this.func_tick, 10000);
        });
      } else {
        this.interval = setTimeout(this.func_tick, 10000);
      }
    },
  },
  async mounted() {
    let api_key = localStorage.getItem('api_key');
    // this.func_get_notice();
    if (api_key) {
      const result = await window.sendRequestAuth('/get_user_info', {});
      if (!result) return;
      this.$store.commit('CHANGE_USER', result);
      this.$store.commit('CHANGE_AUTH', true);
    }
  },
  computed: {
    ...mapGetters(['USER_STATE']),
  },
};
</script>

<style>
@import 'css/style.css';
@import 'css/media.css';

.vue-notification {
  font-size: 16px !important;
}
.show_modal-enter {
  opacity: 0;
}
.show_modal-enter-active {
  transition: 0.3s;
}
.show_modal-enter-to {
  opacity: 1;
}

.show_modal-leave {
  opacity: 1;
}
.show_modal-leave-active {
  opacity: 1;
  transition: all 0.8s ease;
}

.show_modal-leave-to {
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
